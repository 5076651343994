import classes from "./map.module.css";
import { ReactComponent as Mail } from "../assets/mail.svg";
import { ReactComponent as Location } from "../assets/location.svg";
import { ReactComponent as Phone } from "../assets/phone.svg";
import Aos from "aos";
import { useEffect } from "react";

const Map = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className={classes.main}>
      <div
        className={classes.mapContent}
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        <a href="mailto:xyz@everythingmiddleman.org">
          <Mail />
          enquiries@everythingmiddleman.org
        </a>
        <a href="tel:+2347013500003">
          <Phone />
          +234 701 3500 003
        </a>
        <a>
          <Location />
          B105, Providence Event Center and Malls, <br /> Leme, Abeokuta, <br />{" "}
          Ogun state
        </a>
      </div>
      <div
        className={classes.mapContent}
        data-aos="fade-left"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.9695203266147!2d3.3582587751371613!3d7.129522292874395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103a4ba225d9574b%3A0xfa209fa7dbf98a02!2sProvidence%20Event%20Centre%20and%20Malls!5e0!3m2!1sen!2sng!4v1672944602534!5m2!1sen!2sng"
          width="100%"
          height="226"
          style={{ border: 0 }}
          aria-hidden="false"
        ></iframe>
      </div>
    </div>
  );
};

export default Map;
