import { useEffect } from "react";
import Service from "../../components/service";
import { eventData } from "../../constants/services";
import classes from "../business/business.module.css";

const Event = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.main}>
      <div className={classes.bizzContent}>
        <span>OUR SERVICES</span>
        <h1>Events and Entertainment</h1>
        <p>
          We offer event management services aimed at planning and executing
          your events to the standard state. Coupled with our effective
          strategic marketing and promotional skills as lethal weapons, your
          events reach the current and potential customers. We create
          unforgettable event experiences. Introduce your event to us, and let
          us introduce it to the world.
        </p>
      </div>
      <div className={classes.bizzCard}>
        {eventData.map((item, index) => (
          <Service
            index={index}
            {...item}
            key={index}
            modalImage={eventData[index].image}
            modalText={eventData[index].text}
            modalTitle={eventData[index].title}
          />
        ))}
      </div>
    </div>
  );
};

export default Event;
